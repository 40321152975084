import * as React from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Divider, Embed, Header } from 'semantic-ui-react';

import { Body } from '../../utils/body';

import './styles/about.scss';
import './styles/home.scss';



export const Media: React.FC<{}> = ({}) => {
    return <Body title='Successful - Engage - Media'>
        <div className='header' style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/1938.jpg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto'
            }}>
                <Container>
                    <Header>
                        {"Media / Conference"}
                    </Header>
                </Container>
        </div>
        <Container>
                    <Row>
                        <Col md={12} xs={12}>
                            <div className='section mt-3'>
                                <VerticalTimeline lineColor={"#37a6c8"}>
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--education"
                                        contentStyle={{ background: 'rgb(33, 150, 243)' }}
                                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                        date="2024"
                                        style={{textAlign:'left',fontSize: '1.3rem', fontWeight: 'bold'}}
                                        iconStyle={{ background: 'rgb(33, 150, 243)' }}
                                    >
                                        <Carousel fade> 
                                            {
                                                Array.from(Array(18).keys()).map(n=>(
                                                    <Carousel.Item key={`2024-${n}`}>
                                                            <img
                                                                className="d-block w-100"
                                                                style={{maxWidth: 'auto',width:'auto', maxHeight: '20rem',objectFit:'contain'}}
                                                                src={`${process.env.PUBLIC_URL}/2024/${n+1}.jpg`}
                                                            />
                                                    </Carousel.Item>
                                                ))
                                            } 
                                        </Carousel>
                                    </VerticalTimelineElement>
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--education"
                                        date="2023"
                                        style={{fontSize: '1.3rem', fontWeight: 'bold'}}
                                        contentStyle={{ background: 'rgb(33, 150, 243)' }}
                                        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                        iconStyle={{ background: 'rgb(33, 150, 243)' }}
                                    >
                                        <Carousel fade> 
                                            {
                                                Array.from(Array(16).keys()).map(n=>(
                                                    <Carousel.Item key={`2024-${n}`}>
                                                            <img
                                                                className="d-block w-100"
                                                                style={{maxWidth: 'auto',width:'auto', maxHeight: '20rem',objectFit:'contain'}}
                                                                src={`${process.env.PUBLIC_URL}/2023/${n+1}.jpg`}
                                                            />
                                                    </Carousel.Item>
                                                ))
                                            } 
                                        </Carousel>
                                    </VerticalTimelineElement>
                                </VerticalTimeline>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Divider clearing />
                    <div>
                        <Row>
                            <Col md={6}>
                                <Embed
                                    autoplay={false}
                                    active
                                    color='white'
                                    hd={true}
                                    id='Pt-K7gy0ExI'
                                    iframe={{
                                    allowFullScreen: true,
                                    style: {
                                        padding: 2,
                                    },
                                    }}
                                    source='youtube'
                                />
                            </Col>
                            <Col md={6}>
                                <Embed
                                    active
                                    autoplay={false}
                                    color='white'
                                    hd={true}
                                    iframe={{
                                    allowFullScreen: true,
                                    style: {
                                        padding: 2,
                                    },
                                    }}
                                    source='youtube'
                                    id='QCxl0ArPUe4'
                                />
                            </Col>
                        </Row>
                    </div>
                </Container>
    </Body>
}
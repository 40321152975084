import * as React from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import { Card, CardContent, CardDescription, CardHeader, Header } from 'semantic-ui-react';

import { Body } from '../../utils/body';

import './styles/about.scss';
import './styles/home.scss';

const sliderUnit1 = [`${process.env.PUBLIC_URL}/unit1_1.jpeg`,`${process.env.PUBLIC_URL}/unit1_2.jpeg`,`${process.env.PUBLIC_URL}/unit1_3.jpeg`,`${process.env.PUBLIC_URL}/unit1_4.jpeg`];
const sliderUnit2 = [`${process.env.PUBLIC_URL}/unit2_1.jpeg`,`${process.env.PUBLIC_URL}/unit2_2.jpeg`,`${process.env.PUBLIC_URL}/unit2_3.jpeg`,`${process.env.PUBLIC_URL}/unit2_4.jpeg`];
const sliderUnit3 = [`${process.env.PUBLIC_URL}/unit3_1.jpeg`,`${process.env.PUBLIC_URL}/unit3_2.jpeg`];

export const Infrastructure: React.FC<{}> = ({}) => {
    return <Body title='Successful - About - Infrastructure'>
        <div className='header' style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/1938.jpg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto',
                marginBottom: '1rem'
            }}>
                <Container>
                    <Header>
                        {"Infrastructure"}
                    </Header>
                </Container>
        </div>
        <Container>
            <Card fluid raised centered>
                <CardContent>
                <CardHeader textAlign='center'>UNIT-1</CardHeader>
                <CardDescription>
                    <Carousel fade> 
                                    {
                                        sliderUnit1.map(slider=>(
                                            <Carousel.Item key={slider}>
                                                    <img
                                                        className="d-block w-100"
                                                        src={slider}
                                                    />
                                        </Carousel.Item>
                                        ))
                                    } 
                                    
                                </Carousel>
                </CardDescription>
                </CardContent>
            </Card>
            <Card fluid raised centered>
                <CardContent>
                <CardHeader textAlign='center'>UNIT-2</CardHeader>
                <CardDescription>
                    <Carousel fade> 
                    {
                                        sliderUnit2.map(slider=>(
                                            <Carousel.Item key={slider}>
                                                    <img
                                                        className="d-block w-100"
                                                        src={slider}
                                                    />
                                        </Carousel.Item>
                                        ))
                                    } 
                                    
                                </Carousel>
                </CardDescription>
                </CardContent>
            </Card>
            <Card fluid raised centered>
                <CardContent>
                <CardHeader textAlign='center'>UNIT-3</CardHeader>
                <CardDescription>
                    <Carousel fade> 
                                    {
                                        sliderUnit3.map(slider=>(
                                            <Carousel.Item key={slider}>
                                                    <img
                                                        className="d-block w-100"
                                                        src={slider}
                                                    />
                                        </Carousel.Item>
                                        ))
                                    } 
                                    
                                </Carousel>
                </CardDescription>
                </CardContent>
            </Card>
        </Container>
    </Body>
}
import * as React from 'react';
import { CardHeader, Col, Container, Row } from 'react-bootstrap';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Card, CardContent, CardDescription, CardGroup, CardMeta, Header, Icon, Image, Label } from 'semantic-ui-react';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { Body } from '../../utils/body';

import './styles/about.scss';
import './styles/home.scss';
import 'leaflet/dist/leaflet.css';

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });
  
L.Marker.prototype.options.icon = DefaultIcon;

const positionArray = [{
    position: ['22.527590','88.348540'],
    office: 'CORPORATE OFFICE'
},
{
    position: ['22.624240','88.335140'],
    office: 'FACTORY (UNIT-1)'
},
{
    position: ['22.4025321','88.4746623'],
    office: 'FACTORY (UNIT-2)'
},
{
    position: ['22.624240','88.335140'],
    office: 'FACTORY (UNIT-3)'
},]

export const Locate: React.FC<{}> = ({}) => {
    return <Body title='Successful - Locate Us'>
        <div className='header' style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/1938.jpg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto'
            }}>
                <Container>
                    <Header>
                        {"Locate Us"}
                    </Header>
                </Container>
        </div>
        <Container>
                    <Row>
                        <Col md={12} xs={12}>
                            <div className='section mt-3'>
                                <CardGroup centered>
                                    <Card centered={false}>
                                        <CardContent>
                                            <CardHeader as={"h5"}>CORPORATE OFFICE</CardHeader>
                                            <CardMeta>SUCCESS PAPER PRODUCTS</CardMeta>
                                            <CardDescription>
                                                <Label as={'p'} basic>
                                                    <Icon name='address card' /> 64A, HEMANTA BASU SARANI, 3 RD FLOOR, ROOM NO- 302, KOLKATA-700001, WEST BENGAL, INDIA 
                                                </Label>
                                                <Label as={'p'} basic>
                                                    <Icon name='phone' /> <a href='tel:93307 86396'>+91 93307 86396</a> / <a href='tel:90733 05125'>+91 90733 05125</a>   
                                                </Label>
                                                <Label as={'p'} basic>
                                                    <Icon name='mail' /> <a href='mailto:subhendu@successful.ind.in'>subhendu@successful.ind.in</a>
                                                </Label>
                                            </CardDescription>
                                        </CardContent>
                                    </Card>
                                    <Card>
                                        <CardContent>
                                            <CardHeader as={"h5"}>FACTORY (UNIT-1)</CardHeader>
                                            <CardMeta>SUCCESS PAPER PRODUCTS</CardMeta>
                                            <CardDescription>
                                                <Label as={'p'} basic>
                                                    <Icon name='address card' /> 58, N.S ROAD, INSIDE GHOSHALA, NEAR HIMADRI CHEMICAL LILUAH, HOWRAH-711204, WEST BENGAL, INDIA
                                                </Label>
                                                <Label as={'p'} basic>
                                                    <Icon name='phone' /> <a href='tel:90463 28380'>+91 90463 28380</a> / <a href='tel:77972 88956'>+91 77972 88956</a>   
                                                </Label>
                                                <Label as={'p'} basic>
                                                    <Icon name='mail' /> <a href='mailto:salisha742123@gmail.com'>salisha742123@gmail.com</a>
                                                </Label>
                                            </CardDescription>
                                        </CardContent>
                                    </Card>
                                    <Card>
                                        <CardContent>
                                            <CardHeader as={"h5"}>FACTORY (UNIT-2)</CardHeader>
                                            <CardMeta>SUCCESS PAPER PRODUCTS</CardMeta>
                                            <CardDescription>
                                                <Label as={'p'} basic>
                                                    <Icon name='address card' /> AKNA MORE, UNDER SOUTH GARIA GRAM PANCHAYAT, P.S. BARUIPUR, SOUTH GARIA -743613, SOUTH 24 PARGANAS DISTRICT, WEST BENGAL, INDIA
                                                </Label>
                                                <Label as={'p'} basic>
                                                    <Icon name='phone' /> <a href='tel:82402 73302'>+91 82402 73302</a> / <a href='tel:72784 69137'>+91 72784 69137</a>   
                                                </Label>
                                                <Label as={'p'} basic>
                                                    <Icon name='mail' /> <a href='mailto:dipenbose76@gmail.com'>dipenbose76@gmail.com</a>
                                                </Label>
                                            </CardDescription>
                                        </CardContent>
                                    </Card>
                                    <Card>
                                        <CardContent>
                                            <CardHeader as={"h5"}>FACTORY (UNIT-3)</CardHeader>
                                            <CardMeta>ANGAD ENTERPRISES</CardMeta>
                                            <CardDescription>
                                                <Label as={'p'} basic>
                                                    <Icon name='address card' /> 58, N.S ROAD, WARD NO-31, PO- LILUAH, INSIDE GHOSHALA, NEAR HIMADRI CHEMICAL, HOWRAH-711204, WEST BENGAL, INDIA
                                                </Label>
                                                <Label as={'p'} basic>
                                                    <Icon name='phone' /> <a href='tel:90463 28380'>+91 90463 28380</a> / <a href='tel:77972 88956'>+91 77972 88956</a>   
                                                </Label>
                                                <Label as={'p'} basic>
                                                    <Icon name='mail' /> <a href='mailto:salisha742123@gmail.com'>salisha742123@gmail.com</a>
                                                </Label>
                                            </CardDescription>
                                        </CardContent>
                                    </Card>
                                </CardGroup>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <MapContainer style={{height: '400px'}} center={[22.572645, 88.363892]} zoom={10} scrollWheelZoom={false}>
                            <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {
                                positionArray.map(pos=>(
                                    <Marker position={pos.position as any} key={pos.office}>
                                        <Popup keepInView>
                                            {pos.office}
                                        </Popup>
                                    </Marker>
                                ))
                            }
                            
                        </MapContainer>
    </Body>
}
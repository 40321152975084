import React from 'react';
import 'react-bootstrap/helpers';
import 'semantic-ui-css/semantic.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route, BrowserRouter as Router, Routes  } from 'react-router-dom';

import './App.scss';
import './utils/global.scss';
import { Header } from './modules/utils/header';
import { Footer } from './modules/utils/footer';
import { Home } from './modules/home/components/home';
import { Products } from './modules/product/components/products';
import { Product } from './modules/product/components/product';
import { CartComponent } from './modules/cart/components/cart-component';
import { UserPopup } from './modules/user/components/user-popup';
import { About } from './modules/home/components/about';
import { AdminProducts } from './modules/admin/components/Products';
import { PrivateRoutes } from './modules/utils/PrivateRoute';
import { Infrastructure } from './modules/home/components/infrastructure';
import { Locate } from './modules/home/components/locate';
import { Media } from './modules/home/components/media';
import { Event } from './modules/home/components/event';
import { Contact } from './modules/home/components/contact';


function App() {
  return (
    <Router>
      <Header />
      <div style={{marginTop: '6.5rem', background: 'white'}}>
        <Routes>
          <Route path="/products/:categoryId/:title" element={<Products />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/" element={<Home />} />
          <Route path="/about/overview" element={<About />} />
          <Route path="/about/infrastructure" element={<Infrastructure />} />
          <Route path="/contact/contact-us" element={<Contact />} />
          <Route path="/contact/locate-us" element={<Locate />} />
          <Route path="/engage/media" element={<Media />} />
          <Route path="/engage/event" element={<Event />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/admin/products" element={<AdminProducts />} />
          </Route>
        </Routes>
        <UserPopup />
        <CartComponent />
        <ToastContainer />
      </div>
      <Footer />
    </Router>
  );
}

export default App;

import * as React from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';

import { sendEmail, sendEmailResponse } from '../../user/userActions';
import { getEmailResponse, isUserLoading } from '../../user/userSelectors';
import { Body } from '../../utils/body';

import './styles/about.scss';
import './styles/home.scss';
import './styles/contact.scss';

export const Contact: React.FC<{}> = ({}) => {
    const loading = useSelector(isUserLoading);
    const response = useSelector(getEmailResponse);
    const dispatch = useDispatch();
    const onSubmit = (event: any) => {
        event.preventDefault();
        dispatch(sendEmail({
            data: {...formData, to: 'subhendu@successful.ind.in'}
        }))
    }
    React.useEffect(()=>{
        if(response) {
            toast(response==='error'?"Error occured please try again later!":"Message sent succesfully!")
            dispatch(sendEmailResponse({
                type: undefined
            } as any));
            if(response==='success'){
                setFormData({
                    firstName:'',
                    lastName:'',
                    email:'',
                    phoneNumber: '',
                    message: '',
                })
            }
        }
    },[response])
    React.useEffect(()=>{
        return (()=>{
            dispatch(sendEmailResponse({
                type: undefined
            } as any));
        })
    },[])
    const [formData, setFormData] = React.useState({
        firstName:'',
        lastName:'',
        email:'',
        phoneNumber: '',
        message: '',
    });
    const onChange = (key: string) => (event: any) => {
        setFormData(prev=>{
            const nData = {...prev, [key]: event.target.value}
            return nData;
        })
    }
    return <Body title='Successful - Connect - Contact Us'>
        <Container>
                <div className="new_home_web">
                    <div className="responsive-container-block big-container">
                        <div className="responsive-container-block textContainer">
                        <div className="topHead">
                            <p className="text-blk heading">
                            Contact&nbsp;
                            <span className="orangeText">
                                Us
                            </span>
                            </p>
                            <div className="orangeLine" id="w-c-s-bgc_p-2-dm-id">
                            </div>
                        </div>
                        <p className="text-blk subHeading">
                            Success Paper Products
                        </p>
                        </div>
                        <div className="responsive-container-block container">
                        <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-7 wk-ipadp-10 line" id="i69b">
                            <form className="form-box" onSubmit={onSubmit}>
                            <div className="container-block form-wrapper">
                                <div className="responsive-container-block">
                                <div className="left4">
                                    <div className="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6" id="i10mt-2">
                                    <input required onChange={onChange("firstName")} className="input" id="ijowk-2" name="firstName" placeholder="First Name" />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                    <input required onChange={onChange("lastName")} className="input" id="indfi-2" name="lastName" placeholder="Last Name" />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                                    <input required onChange={onChange("email")} className="input" type='email' id="ipmgh-2" name="email" placeholder="Email Address" />
                                    </div>
                                    <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12 lastPhone">
                                    <input required onChange={onChange("phoneNumber")} className="input" type='tel' id="imgis-2" name="phoneNumber" placeholder="Phone Number" />
                                    </div>
                                </div>
                                <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="i634i-2">
                                    <textarea onChange={onChange("message")} className="textinput" id="i5vyy-2" placeholder="Message" name='message'></textarea>
                                </div>
                                </div>
                                <Button loading={loading} disabled={loading} className="send" id="w-c-s-bgc_p-1-dm-id">
                                Send
                                </Button>
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                </Container>
    </Body>
}
import { createAction } from "@reduxjs/toolkit";

import { ILogInAction, IRecivedLoginResponseAction, IRecivedSignupResponseAction, ISendEmailAction, ISendEmailResponseAction, ISetLoggedInUserAction, ISetUserBooleanAction, ISignUpAction } from "./types/action";


export const SET_USER_POPUP_VISIBLE = "user/SET_USER_POPUP_VISIBLE";
export const SET_LOGGEDIN_USER = "user/SET_LOGGEDIN_USER";
export const USER_LOGIN: string = "user/LOGIN";
export const USER_SIGNUP: string = "user/SIGNUP";
export const RECIEVED_LOGIN_RESPONSE = "user/RECIEVED_LOGIN_RESPONSE";
export const RECIEVED_SIGNUP_RESPONSE = "user/RECIEVED_SIGNUP_RESPONSE";
export const SEND_EMAIL = "user/SEND_EMAIL";
export const SEND_EMAIL_RESPONSE = "user/SEND_EMAIL_RESPONSE";


export const setUserPopupVisible = createAction<ISetUserBooleanAction>(SET_USER_POPUP_VISIBLE);
export const setLoggedInUser = createAction<ISetLoggedInUserAction>(SET_LOGGEDIN_USER);
export const login = createAction<ILogInAction>(USER_LOGIN);
export const signUp = createAction<ISignUpAction>(USER_SIGNUP);
export const recivedLoginResponse = createAction<IRecivedLoginResponseAction>(RECIEVED_LOGIN_RESPONSE);
export const recivedSignupResponse = createAction<IRecivedSignupResponseAction>(RECIEVED_SIGNUP_RESPONSE);
export const sendEmail = createAction<ISendEmailAction>(SEND_EMAIL);
export const sendEmailResponse = createAction<ISendEmailResponseAction>(SEND_EMAIL_RESPONSE);
import * as React from 'react';
import { Button, Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { useCookies } from 'react-cookie';

import { getCategories } from '../product/productSelectors';
import { CartIcon } from '../cart/components/cart-icon';
import { getLoggedInUser } from '../user/userSelectors';
import { setLoggedInUser, setUserPopupVisible } from '../user/userActions';

import './styles/header.scss';




export const Header: React.FC<{}> = () => {
  const categories =  useSelector(getCategories);
  const loggedInUser = useSelector(getLoggedInUser);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const openLogin = () => {
    dispatch(setUserPopupVisible({
      value: true
    }))
  }
  const logOut = () => {
    removeCookie('token');
    dispatch(setLoggedInUser({ user: undefined }));
  }
  
  return (
    <Navbar fixed="top" bg="dark" data-bs-theme="dark" expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt="Successful"
              src={`${process.env.PUBLIC_URL + '/logo.png'}`}
              style={{width: '19rem', height: 'auto'}}
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end flex-grow-1 pe-3">
            <Nav>
              <Nav.Item>
                <Link className='nav-link' to="/">Home</Link>
              </Nav.Item>
              <NavDropdown title="About" id="basic-nav-dropdown">
                <NavDropdown.Item key={'overview'}>
                     <Link className='nav-link' to={`/about/overview`}>Overview</Link>
                </NavDropdown.Item>
                <NavDropdown.Item key={'infrastructure'}>
                     <Link className='nav-link' to={`/about/infrastructure`}>Infrastructure</Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Products" id="basic-nav-dropdown">
                {
                  (categories||[]).map(category=>(
                    <NavDropdown.Item key={category.id}>
                      <Link className='nav-link' to={`/products/${category.id}/${category.category}`}>{category.category}</Link>
                    </NavDropdown.Item>
                  ))
                }
              </NavDropdown>
              <NavDropdown title="Engage" id="basic-nav-dropdown">
                <NavDropdown.Item key={'media'}>
                     <Link className='nav-link' to={`/engage/media`}>Media</Link>
                </NavDropdown.Item>
                <NavDropdown.Item key={'event'}>
                     <Link className='nav-link' to={`/engage/event`}>Event</Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Connect" id="basic-nav-dropdown">
                <NavDropdown.Item key={'contact-us'}>
                     <Link className='nav-link' to={`/contact/contact-us`}>Contact Us</Link>
                </NavDropdown.Item>
                <NavDropdown.Item key={'locate-us'}>
                     <Link className='nav-link' to={`/contact/locate-us`}>Locate Us</Link>
                </NavDropdown.Item>
              </NavDropdown>
              {
                loggedInUser ? 
                <Nav.Item style={{color: 'white', padding: '0.5rem'}}>
                  {`Hi, ${loggedInUser.firstName}`}{" "}<Icon onClick={logOut} fitted name='sign-out' />
                </Nav.Item>
                :
                <Nav.Item className='me-1'>
                  <Button onClick={openLogin}>Login / Sign Up</Button>
                </Nav.Item>
              }
              {
                (loggedInUser && loggedInUser.roles?.includes("admin")) &&
                <>
                  <Nav.Item>
                    <Link className='nav-link' to="/admin/products">Admin Dashboard</Link>
                  </Nav.Item>
                </>
              }
              <Nav.Item>
                <CartIcon />
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )
}
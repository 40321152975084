import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Header, Image } from 'semantic-ui-react';

import { Body } from '../../utils/body';

import './styles/about.scss';
import './styles/home.scss';


export const About: React.FC<{}> = ({}) => {
    return <Body title='Successful - About - Overview'>
        <div className='header' style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/1938.jpg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto'
            }}>
                <Container>
                    <Header>
                        {"Overview"}
                    </Header>
                </Container>
        </div>
        <div style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/bg-image-2.jpg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            }}>
                <Container>
                    <Row>
                        <Col md={6} xs={12}>
                            <div className='section mt-3'>
                                <div className='section-header'>
                                    <Header as={"h3"}>
                                    Success Paper Products is one of the leading manufacturers of Writing Notebook, science division item, Drawing Book,Graph book, Diary,TableCalendar,Book cover synthetic and paper roll,Duplicate book, Cash Memo Book, Paper Register, Envelope, Pos roll etc. We direct all our activities to cater the expectations of customers by providing them excellent quality products as per their gratifications. Moreover, we follow moral business policies and crystal pure transparency in all our transactions to keep healthy relations with the customers.
                                    </Header><br />
                                    <Header className='text-white' as={"h3"}>
                                    Success Paper Products was started on year 2011.Within the span of 10 years it took very strong position in student and office stationery market of eastern India. We enjoy good position in supply to School. Now the company is very much clear about the further vision of Pan India marketing and Export by 2024 of its manufactured products.<br />
                                    We are grateful to the entire team of Success Paper Products, whose consistent effort and dedication have been useful to us for attaining exponential development in the current market.
                                    </Header>
                                </div> 
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            <Image className='image-big' centered wrapped size='massive' src={`${process.env.PUBLIC_URL}/banner-img.png`} />
                        </Col>
                    </Row>
                </Container>
        </div>
    </Body>
}
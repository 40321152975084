import * as React from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import { Header } from 'semantic-ui-react';

import { Body } from '../../utils/body';

import './styles/about.scss';
import './styles/home.scss';


export const Event: React.FC<{}> = ({}) => {
    return <Body title='Successful - Engage - Event'>
        <div className='header' style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/1938.jpg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto'
            }}>
                <Container>
                    <Header>
                        {"Event"}
                    </Header>
                </Container>
        </div>
        <div style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/bg-image-2.jpg'})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
            }}>
                <Container>
                    <Row>
                        <Col md={12} xs={12}>
                            <Carousel fade> 
                                {
                                    Array.from(Array(8).keys()).map(n=>(
                                        <Carousel.Item key={`2024-${n}`}>
                                            <img
                                                className="d-block w-100"
                                                style={{maxWidth: 'auto',width:'auto', maxHeight: '30rem',objectFit:'contain'}}
                                                src={`${process.env.PUBLIC_URL}/events/${n+1}.jpeg`}
                                            />
                                        </Carousel.Item>
                                    ))
                                } 
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
        </div>
    </Body>
}
import { put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import AxiosLoginInstance from "../../../AxiosLoginIntance";
import { ISendEmailAction } from "../types/action";
import { IUser } from "../types/state";
import { sendEmailResponse, } from "../userActions";

export function* sendEmailSaga({ payload }: PayloadAction<ISendEmailAction>) {
    try {
        if(payload?.data) {
            yield sendEmail(payload.data);
            
            yield put(sendEmailResponse({
                type: 'success'
            }));
        }
    }catch(ex: any) {
        yield put(sendEmailResponse({
            type: 'error'
        }));
        console.warn(ex);
    }
}

const sendEmail = async(data: any) => {
    const response = (await AxiosLoginInstance.post<IUser>(`/user/user-api/send-email`, data));
    return response.data;
}